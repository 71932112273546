import React from "react";
import { AppProvider } from "./src/context";
import GTM from "@utils/GTM";

import "@styles/global.css";
import "@styles/cookies.css";
import "@styles/screenshots.css";
import "/static/fonts/fonts.css";

export const onClientEntry = () => {
  document.documentElement.lang = "en";
};

export const onRouteUpdate = ({ location }) => {
  GTM.reset();
};

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
