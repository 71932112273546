import React, { useState } from "react";

const defaultState = {
  token: null,
};

const AppContext = React.createContext(defaultState);

const AppProvider = ({ children }) => {
  const [token, setToken] = useState(defaultState.token);

  const store = {
    token: token,
    setToken: setToken,
  };
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
