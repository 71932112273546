export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false;
export const mobileMedia = "(min-width: 0px) and (max-width: 1023px)";
export const tabletMedia = "(min-width: 1024px) and (max-width: 1279px)";

export const isMobile = (media = false) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(media || mobileMedia).matches;
  }

  return true;
};

export const isTablet = () => {
  if (typeof window !== "undefined") {
    return window.matchMedia(tabletMedia).matches;
  }

  return true;
};

export const debounce = (func, wait, immediate) => {
  let timeout = null;

  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const focusLoop = (e, selector) => {
  const tabPressed = e.key === "Tab" || e.keyCode === 9;

  if (!tabPressed) return;

  const elems = document.querySelectorAll(selector);

  if (elems?.length) {
    const firstElem = elems[0];
    const lastElem = elems[elems.length - 1];

    if (!Array.from(elems).includes(document.activeElement)) {
      firstElem.focus();
    }

    if (e.shiftKey) {
      if (document.activeElement === firstElem) {
        lastElem.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastElem) {
        firstElem.focus();
        e.preventDefault();
      }
    }
  }
};

export const getUrlParameter = (param) => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
